<template>
  <div>
    <div class="site-section bg-light">
      <h1 class="mb-2" style="text-align: center;">{{$t("pages.about.company.title")}}</h1>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12" data-aos="fade-up" data-aos-delay="100">
            <br />
            <p style="text-align:center; font-size:18px" v-html="$t('pages.about.company.description')"></p>
            <p style="text-align:center; font-size:18px" v-html="$t('pages.about.company.description_two')"></p>
          </div>

          <div class="row">
            <div class="col-md-1"></div>
            <div class="post-content  col-md-10" align="center">
              <p class="quote">
                <span
                  > {{$t("pages.about.slogan")}}</span
                >
              </p>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.mision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                {{$t("pages.about.mision.description")}}
              </p>
            </div>

            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.vision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                {{$t("pages.about.vision.description")}}
              </p>
            </div>
            <div class="col-md-6 col-lg-4">
              <h2 class="service-heading text-center">{{$t("pages.about.values.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; text-transform: uppercase;">
                <ul class="values">
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_1")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_2")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_3")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_4")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_5")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_6")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_7")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_8")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_9")}}</li>
                  <li><i class="icon-check" aria-hidden="true"></i> {{$t("pages.about.values.list_values.example_10")}}</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {

  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
ul {
  list-style: none ;
}

.values i{
  color: #967c2d;
}
</style>
